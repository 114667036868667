import { motion } from "framer-motion"
import React, {useState,useEffect,useRef} from "react"
import { Images } from './Images';
import axios from "axios";

import Cookies from 'js-cookie';

import "bootstrap/dist/css/bootstrap.min.css";

// Import Main styles for this application
import './assets/css/style.css'

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const getBackgroundSize = (value) => {
  return { backgroundSize: `${(value * 100) / 1000}% 100%` };
};
 
const formatForDisplay = (number, includeDecimals) => {
  return number.toString().padStart(6, "0")
    .split("")
    .reverse();
};

const DecimalColumn = ({ fontSize, color }) => {
  return (
    <div>
      <span
        style={{
          fontSize: fontSize,
          lineHeight: fontSize,
          color: color
        }}
      >
        .
      </span>
    </div>
  );
};

const NumberColumn = ({
  digit,
  delta,
  fontSize,
  color,
  incrementColor,
  decrementColor
}) => {
  const [position, setPosition] = useState(0);
  const [animationClass, setAnimationClass] = useState(null);
  const previousDigit = usePrevious(digit);
  const columnContainer = useRef();

  const setColumnToNumber = (number) => {
    setPosition(columnContainer.current.clientHeight * parseInt(number, 10));
  };

  useEffect(() => setAnimationClass(previousDigit !== digit ? delta : ""), [
    digit,
    delta
  ]);



  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <div
      className="ticker-column-container"
      ref={columnContainer}
      style={{
        fontSize: fontSize,
        lineHeight: fontSize,
        color: color,
        height: "auto",
        "--increment-color": incrementColor,
        "--decrement-color": decrementColor
      }}
    >
      <motion.div
        animate={{ x: 0, y: position }}
        className={`ticker-column ${animationClass}`}
        onAnimationComplete={() => setAnimationClass("")}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
          <div key={num} className="ticker-digit">
            <span
              style={{
                fontSize: fontSize,
                lineHeight: fontSize,
                color: color,
              }}
            >
              {num}
            </span>
          </div>
        ))}
      </motion.div>
      <span className="number-placeholder">000000</span>
    </div>
  );
};

// Counter component
const AnimatedCounter = ({
  value = 0,
  fontSize = "18px",
  color = "white",
  incrementColor = "#32cd32",
  decrementColor = "#fe6862",
  includeDecimals = false
}) => {
  const numArray = formatForDisplay(value, includeDecimals);
  const previousNumber = usePrevious(value);

  let delta = null;
  if (value > previousNumber) delta = "increase";
  if (value < previousNumber) delta = "decrease";

  return (
    <motion.div layout className="ticker-view">
      {numArray.map((number, index) =>
        <NumberColumn
        key={index}
        digit={number}
        delta={delta}
        fontSize={fontSize}
        incrementColor={incrementColor}
        decrementColor={decrementColor}
        includeDecimals={includeDecimals}
      />
      )}
    </motion.div>
  );
};

function App() {
  const [counterValue, setCounterValue] = useState(0);
  const [isPledging, SetisPledging] = useState(false);
  const [thankyou, SetThankYou] = useState(false);
  const [URL, setURL] = useState("https://me.letsbenice.sg/process.php");
  const [lastCount, setLastCount] = useState(0);

  useEffect(()=>{
    document.addEventListener("keydown", keyPress, false);
    handleLoad()
    if(typeof Cookies.get("hasPledged") != "undefined" ){
      SetThankYou(true);
    } 
    setInterval(()=>{handleLoad()}, 5000)
    return ()=>{
      document.removeEventListener("keydown", keyPress, false);
    }
  })

  const handlePledge = () =>{
    if(isPledging || typeof Cookies.get("hasPledged") != "undefined" ){
      console.log("user has pledged.")
      return;
    } 
    SetisPledging(true);
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios.post(`${URL}`, {
      action: "pledge",
      pledge: "manual",
    }).then(function(res){
        let {data} = res;
        SetisPledging(false);
        SetThankYou(true);
        setTimeout(()=>{SetThankYou(false)},5000)
        Cookies.set("hasPledged", "yes", {path: "/", expires : 1000});
    }).catch(function(er){
      SetisPledging(false);
    })
  }

  const handleLoad = () =>{
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    axios.post(`${URL}`, {
      action: "getCount",
    }).then(function(res){
        let {data} = res;
        setCounterValue(data.count)
    }).catch(function(er){
      
    })
  }
  
  const keyPress = (e) =>{ 
    if (e.key === " ") { 
      let cnt = counterValue + 1
      setCounterValue(cnt)
    }
  }

  const reset = () =>{
    setCounterValue(0)
  }

  const getMobileOpS = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

    const linkIG = () =>{
        if(getMobileOpS() == "Android"){
            window.location.href = "intent://www.instagram.com/cleanandgreensg/#Intent;package=com.instagram.android;scheme=https;end"
        }else if(getMobileOpS() == "iOS"){
            window.location.href = "instagram://user?username=cleanandgreensg"
        }else{
            window.location.href = "https://www.instagram.com/cleanandgreensg/"
        }
    }
    
    const linkFB = () =>{
        if(getMobileOpS() == "Android"){
            window.location.href = "intent://profile/100064589178353#Intent;package=com.facebook.katana;scheme=fb;end"
        }else if(getMobileOpS() == "iOS"){
            window.location.href = "fb://profile/100064589178353"
        }else{
            window.location.href = "https://www.facebook.com/CGSingapore/"
        }
    }

    const scrolltotop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

  return (
    <div className="main">
      <div className="sec-1">
        <div className="m-view">
        <div className="custom">
          <div className="header-image">
            <img src={Images.asset328} alt="" />
          </div>
          <div className="data-1 text-center">
            <img src={Images.asset318} alt="" />
          </div>
        </div>
        <ul className="list-one">
          <li className="img-1"><img src={Images.asset128} alt="" /></li>
          <li className="img-2"><img src={Images.asset48} alt="" /></li>
        </ul>
        </div>
        <div className="d-view">
          <div className="header-image">
            <img src={Images.hdr} alt="" />
          </div>
        </div>
      </div>
      <div className="sec-5">
        <div className="sec-5-img App">
          <div className="frame">
            <div className="inner">
              <div className="block">
                <div className="seg"></div>
                <div className="seg"></div>
                <div className="seg"></div>
                <div className="seg"></div>
                <div className="seg"></div>
                <div className="seg"></div>
              </div>
            <AnimatedCounter value={counterValue} fontSize='31vh'/>
          </div>
        </div>
        {!thankyou ? 
        <><img className="jumbotron" src={Images.people} alt="" />
        <a onClick={handlePledge}><img className="pledge" src={Images.pledge} /></a>
        <img className="note" src={Images.note} /></>:
        <div>
          <img className="thankyou" src={Images.thankyou} alt="" />
          <img className="subtxt" src={Images.subtxt} alt="" />
        </div>}
      
      </div>
      <ul class="m-view list-fiver">
          <li class="img-1"><img src={Images.asset258} alt="" /></li>
          <li class="img-2"><img src={Images.asset258} alt="" /></li>
          <li class="img-3"><img src={Images.asset268} alt="" /></li>
          <li class="img-4"><img src={Images.asset168} alt="" /></li>
          <li class="img-5"><img src={Images.asset258} alt="" /></li>
        </ul>
      </div>
      <div className="sec-2">
        <div className="m-view">
        <div className="custom">
          <div className="sec-2-img">
            <img src={Images.asset308} alt="Asset" useMap="#workmap"  />
          </div>
         <a className="sec-2-btn btn" target="_blank" href="https://rhythm.letsbenice.sg/"></a>
        </div>
        </div>
        <div className="d-view">
          <div className="sec-2-img">
            <img src={Images.cta} alt="Asset" useMap="#workmap"  />
          </div>
          <a className="sec-2-btn btn" target="_blank" href="https://rhythm.letsbenice.sg/"></a>
        </div>
      </div>
      <div className="m-view">
      <div className="sec-3">
        <div className="custom">
          <div className="sec-3-img">
            <img src={Images.asset298} alt="" />
          </div>
        </div>
      </div>

      <div className="sec-4">
        <div className="custom">
          <div className="sec-4-img">
            <img src={Images.asset278} alt="" />
          </div>
        </div>
        <ul className="list-four">
          <li className="img-1"><img src={Images.asset118} alt="" /></li>
          <li className="img-2"><img src={Images.asset58} alt="" /></li>
          <li className="img-3"><img src={Images.asset248} alt="" /></li>
          <li className="img-4"><img src={Images.asset258} alt="" /></li>
          <li className="img-5"><img src={Images.asset128} alt="" /></li>
          <li className="img-6"><img src={Images.asset58} alt="" /></li>
        </ul>
      </div>

      <div className="sec-5">
        <div className="custom">
          <div className="sec-5-img">
            <img src={Images.asset228} alt="" />
          </div>
        </div>
        <ul className="list-five">
          <li className="img-1"><img src={Images.asset238} alt="" /></li>
          <li className="img-2"><img src={Images.asset218} alt="" /></li>
          <li className="img-3"><img src={Images.asset208} alt="" /></li>
          <li className="img-4"><img src={Images.asset78} alt="" /></li>
        </ul>
      </div>

      <div className="sec-6">
        <div className="custom">
          <div className="sec-6-img">
            <img src={Images.asset188} alt="" />
          </div>
          <div className="sec-6-data">
            <img src={Images.asset178} alt="" />
          </div>
        </div>
        <ul className="list-six">
          <li className="img-1"><img src={Images.asset118} alt="" /></li>
          <li className="img-2"><img src={Images.asset68} alt="" /></li>
          <li className="img-3"><img src={Images.asset128} alt="" /></li>
          <li className="img-4"><img src={Images.asset198} alt="" /></li>
        </ul>
      </div>
      </div>
      <div className="d-view">
        <div className="sec-6-img">
            <img src={Images.grouped} alt="" />
          </div>
      </div>

      <footer>
        <a onClick={scrolltotop}><img src={Images.btt} className="stt" alt="" /></a>
        <div className="custom-footer">
          <div className="row align-items-center">
            <div className="col-8">
              <ul className="social-list">
                <li className="insta">
                  <a onClick={linkIG}><img src={Images.instagram} className="me-2" alt="" />
                    @cleanandgreensg</a>
                </li>
                <li className="facebook">
                  <a onClick={linkFB}><img src={Images.facebook} className="me-2 facebook" alt="" />
                    Clean & Green Singapore
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-4">
             <div className="footer-logo">
              <a href="https://cgs.gov.sg"><img src={Images.asset2} alt="" /></a>
             </div>
            </div>
          </div>
        </div>
      </footer>

    </div>
  );
}

export default App;
