export const Images = {
    asset328 : require("./assets/images/Asset 32-8.png"),
    asset318 : require("./assets/images/Asset 31-8.png"),
    asset128 : require("./assets/images/Asset 12-8.png"),
    asset48 : require("./assets/images/Asset 4-8.png"),
    asset108 : require("./assets/images/Asset 10-8.png"),
    asset98 : require("./assets/images/Asset 9-8.png"),
    asset118 : require("./assets/images/Asset 11-8.png"),
    asset308 : require("./assets/images/Asset 30-8.png"),
    asset298 : require("./assets/images/Asset 29-8.png"),
    asset278 : require("./assets/images/Asset 27-8.png"),
    asset58 : require("./assets/images/Asset 5-8.png"),
    asset248 : require("./assets/images/Asset 24-8.png"),
    asset268 : require("./assets/images/Asset 26-8.png"),
    asset258 : require("./assets/images/Asset 25-8.png"),
    asset228 : require("./assets/images/Asset 22-8.png"),
    asset238 : require("./assets/images/Asset 23-8.png"),
    asset218 : require("./assets/images/Asset 21-8.png"),
    asset208 : require("./assets/images/Asset 20-8.png"),
    asset78 : require("./assets/images/Asset 7-8.png"),
    asset188 : require("./assets/images/Asset 18-8.png"),
    asset178 : require("./assets/images/Asset 17-8.png"),
    asset168 : require("./assets/images/Asset 16-8.png"),
    asset68 : require("./assets/images/Asset 6-8.png"),
    asset198 : require("./assets/images/Asset 19-8.png"),
    asset2 : require("./assets/images/image 2.png"),
    facebook : require("./assets/images/facebook.png"),
    instagram : require("./assets/images/instagram.png"),
    people : require("./assets/images/people.png"),
    pledge : require("./assets/images/pledge.png"),
    hdr : require("./assets/images/hdr.png"),
    cta : require("./assets/images/cta.png"),
    grouped : require("./assets/images/grouped.png"),
    subtxt : require("./assets/images/subtxt.png"),
    thankyou : require("./assets/images/thankyou.png"),
    note : require("./assets/images/note.png"),
    btt : require("./assets/images/btt.png"),
}